import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../App.css';
interface NaviProps {

}

export const Navi: React.FC<NaviProps> = (props) => {
    const navigate = (link: string) => {
        window.location.href = link;
    }

    return (
        <div className='Navigation'>
            <div className="FloveeNavi">
                <div className="HomeNavi" onClick={() => { navigate("/") }}>
                    Strona główna
                </div>
                <div className="NodeNavi" onClick={() => { navigate("/oferta") }}>
                    Cennik
                </div>
                <div className="NodeNavi" onClick={() => { navigate("/kontakt") }}>
                    Kontakt
                </div>
                <a className="LastNodeNavi"
                    href={require('../Files/Oferta Zielone Wizje.pdf')}
                    download="Oferta Zielone Wizje"
                    target="_blank"
                    rel="noreferrer">
                    Pobierz ofertę
                </a>
            </div>
        </div>
    )
}