import React from 'react';
import './App.css';
import { FloveeHeader } from './Components/Header';
import { Navi } from './Components/Navi';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';



import { NotFound } from './Pages/notfound';
import { Home } from './Pages/home';
import { ContactPage } from './Pages/contact';
import { Footer } from './Components/Footer';
import { Offer } from './Pages/offer';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/kontakt",
      element: <ContactPage />,
    },
    {
      path: "/oferta",
      element: <Offer />,
    },

    {
      element: <NotFound />,
    }
  ]);
  return (
    <React.StrictMode>
      <div className='Root'>
        <FloveeHeader imageUrl='test' contactTitle='title' />
        <Navi />
        <RouterProvider router={router} />
        <Footer />
      </div>
    </React.StrictMode>


  );
}

export default App;

