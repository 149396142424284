import logo from '../Images/logo.jpg'
import { Icon } from '@fluentui/react/lib/Icon';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../App.css';
import { Banners } from '../MockupData/Banners';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Image1 from '../Images/SelectUs/Image1.png'
import Image2 from '../Images/SelectUs/Image2.png'
import Image3 from '../Images/SelectUs/Image3.png'
interface ProjectsProps {

}

export const Projects: React.FC<ProjectsProps> = (props) => {
    const {

    } = props;

    return (
        <div className='SelectUs'>
            <div className='ProjectsHeader'>
                OSTATNIE PROJEKTY
            </div>
            <div className='SelectUsRow'>
                <div className='ProjectsCell'>
                    <img className="demoImage" src={Image1}></img>
                    <div className='imageTitle'>Rabata przed domem szeregowym</div>
                </div>
                <div className='ProjectsCell'>
                    <img className="demoImage" src={Image2}></img>
                    <div className='imageTitle'>Ogród do 500m2</div>
                </div>
                <div className='ProjectsCell'>
                    <img className="demoImage" src={Image3}></img>
                    <div className='imageTitle'>Rabata przed domem szeregowym</div>
                </div>
            </div>
            {/* <div className='SelectUsRow'>
                <div className='ProjectsCell'>
                    <img className="demoImage" src={demoImage}></img>
                    <div className='imageTitle'>Nowoczesny ogród pod Poznaniem</div>
                </div>
                <div className='ProjectsCell'>
                    <img className="demoImage" src={demoImage}></img>
                    <div className='imageTitle'>Nowoczesny ogród pod Poznaniem</div>
                </div>
                <div className='ProjectsCell'>
                    <img className="demoImage" src={demoImage}></img>
                    <div className='imageTitle'>Nowoczesny ogród pod Poznaniem</div>
                </div>
            </div> */}
        </div>
    )
}