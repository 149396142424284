import React from 'react';

interface OfferProps {

}


export const Offer: React.FC<OfferProps> = (props) => {

  return (
    <div className='OfferArea'>
      <div className='SmallGardenTitle'>Ogród &lt;250 m2</div>
      <div className='SmallGarden'>
        <div className='option'>
          <div className='optionTitle'>Pakiet mini</div>
          <ul className='optionsPointers'>
            <li>moodboard (kolaż inspiracji)</li>
            <li>dwie koncepcje projektowe 2D (jedna do wyboru)</li>
          </ul>
          <div className='optionPrice'>Cena: 1300 zł</div>
        </div>
        <div className='option'>
          <div className='optionTitle'>Pakiet midi</div>
          <ul className='optionsPointers'>
            <li>moodboard (kolaż inspiracji)</li>
            <li>dwie koncepcje projektowe 2D (jedna do wyboru)</li>
            <li>projekt zagospodarowania terenu</li>
            <li>szczegółowy plan nasadzeń wraz z zaleceniami pielęgnacyjnymi</li>
            <li>wizualizacje 3D</li>
          </ul>
          <div className='optionPrice'>Cena: 1750-2150 zł</div>
        </div>
        <div className='option'>
          <div className='optionTitle'>Pakiet maxi</div>
          <ul className='optionsPointers'>
            <li>moodboard (kolaż inspiracji)</li>
            <li>dwie koncepcje projektowe 2D (jedna do wyboru)</li>
            <li>projekt zagospodarowania terenu </li>
            <li>szczegółowy plan nasadzeń wraz z zaleceniami pielęgnacyjnymi</li>
            <li>wizualizacje 3D </li>
            <li>projekt nawierzchni, oświetlenia, nawadniania</li>
          </ul>
          <div className='optionPrice'>Cena: 2250-2600 zł</div>
        </div>
      </div>

      <div className='SmallGardenTitle'>Ogród 250-500 m2</div>
      <div className='SmallGarden'>
        <div className='option'>
          <div className='optionTitle'>Pakiet mini</div>
          <ul className='optionsPointers'>
            <li>moodboard (kolaż inspiracji)</li>
            <li>dwie koncepcje projektowe 2D (jedna do wyboru)</li>
          </ul>
          <div className='optionPrice'>Cena: 2000 zł</div>
        </div>
        <div className='option'>
          <div className='optionTitle'>Pakiet midi</div>
          <ul className='optionsPointers'>
            <li>moodboard (kolaż inspiracji)</li>
            <li>dwie koncepcje projektowe 2D (jedna do wyboru)</li>
            <li>projekt zagospodarowania terenu</li>
            <li>szczegółowy plan nasadzeń wraz z zaleceniami pielęgnacyjnymi</li>
            <li>wizualizacje 3D</li>
          </ul>
          <div className='optionPrice'>Cena: 3600-4300 zł</div>
        </div>
        <div className='option'>
          <div className='optionTitle'>Pakiet maxi</div>
          <ul className='optionsPointers'>
            <li>moodboard (kolaż inspiracji)</li>
            <li>dwie koncepcje projektowe 2D (jedna do wyboru)</li>
            <li>projekt zagospodarowania terenu </li>
            <li>szczegółowy plan nasadzeń wraz z zaleceniami pielęgnacyjnymi</li>
            <li>wizualizacje 3D </li>
            <li>projekt nawierzchni, oświetlenia, nawadniania</li>
          </ul>
          <div className='optionPrice'>Cena: 4500-5200 zł</div>
        </div>
      </div>

      <div className='BigGardenTitle'>Projekt rabaty</div>
      <div className='SmallGarden'>
        <div className='option'>
          <div className='optionTitle'>0-50m2</div>
          <ul className='optionsPointers'>
            <li>dwie koncepcje projektowe (jedna do wyboru)</li>
            <li>rzut 2D z rozmieszczeniem roślin</li>
          </ul>
          <div className='optionPrice'>Cena: 500.00 zł</div>
        </div>
        <div className='option'>
          <div className='optionTitle'>50-100m2</div>
          <ul className='optionsPointers'>
            <li>dwie koncepcje projektowe (jedna do wyboru)</li>
            <li>rzut 2D z rozmieszczeniem roślin</li>
          </ul>
          <div className='optionPrice'>Cena: 1000 zł</div>
        </div>
      </div>
    </div>
  )
}