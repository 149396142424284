
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../App.css';
import emailjs from "@emailjs/browser"
import SendIcon from "@mui/icons-material/Send"
import Button from "@mui/material/Button"
import profilowe from '../Images/profilowe2.jpg'
interface ContactPageProps {

}

export const ContactPage: React.FC<ContactPageProps> = (props) => {

    const submit = (e: any) => {
        e.preventDefault();
        emailjs.sendForm('service_yqbrbed', 'template_tw4wg9j', ".contact_form", 'z1V1jfRJSHULpDgep').then(response => {
            let answer = window.confirm("Wiadomość została wysłana");
            if (answer) {
                window.location.reload();
            };
        })
    }

    const { innerWidth: width } = window;
    const phoneOption = width < 1001;
    let styleClasses = {
        "contact_form_content": "contact_form_content_PC",
        "MoreInformations": "MoreInformations_PC",
        "profileImageContact": "profileImageContact_PC"
    }
    if (phoneOption) {
        styleClasses = {
            "contact_form_content": "contact_form_content_Phone",
            "MoreInformations": "MoreInformations_Phone",
            "profileImageContact": "profileImageContact_Phone"
        }
    }
    return (
        <>
            <div className="ContactPage">
                <div className="ContactLocation">Projektuję ogrody we Wrocławiu i okolicach. </div>


                <div className="contact_area">

                    <div className={styleClasses.contact_form_content}>
                        <div className="ContactDecription">
                            Masz nieduże podwórko, które na ten moment nie przypomina miejsca do relaksu i brak jest w nim prywatności?
                            Jeszcze w tym sezonie możesz rozpocząć współpracę ze mną, aby ogród juz wkrótce stał się Twoim ulubionym miejscem do wypoczynku.
                        </div>
                        <div className={styleClasses.MoreInformations}>
                            <div className="additionalText">
                                <div className="whyItWorth">Dlaczego warto ze mną współpracować?</div>
                                <div>
                                    <li>Tworzę rozwiązania w których istotną rolę odgrywa funkcjonalność i estetyka.</li>
                                    <li>Zwracam szczególną uwagę, aby ogród nawiązywał stylem do budynku i stał się jego integralną częścią </li>
                                    <li>Potrafię wsłuchać się w potrzeby klienta. Jestem otwarta na oryginalne pomysły, które pomogę wdrożyć w życie</li>
                                </div>
                            </div>
                            <div className={styleClasses.profileImageContact}>
                                <img className={styleClasses.profileImageContact} alt="demoImage" src={profilowe}></img>
                            </div>
                        </div>
                        <div className="ContactWithUs">Skontaktuj się ze mną</div>

                        <div className="ContactWithUs">
                            <a href="mailto:zielonewizje.projekty@gmail.com">zielonewizje.projekty@gmail.com</a>
                            <div>tel: 881 341 490</div>
                        </div>


                    </div>
                    <form className="contact_form">
                        <label className="contact_form_label" htmlFor="form_name">Imię</label>
                        <input className="contact_form_input" type='text' name='form_name' id="form_name" placeholder="Imię" />

                        <label className="contact_form_label" htmlFor="emailFrom">Email</label>
                        <input className="contact_form_input" type='text' name='email_from' id="emailFrom" placeholder="Email" />
                        <label className="contact_form_label" htmlFor="message">Wiadomość</label>
                        <textarea className="contact_form_textarea" name='message' id="message" placeholder="Wiadomość" />
                        <label className="contact_form_label" htmlFor="field_area">Powierzchnia działki</label>
                        <input className="contact_form_input" type='text' name='field_area' id="field_area" placeholder="Powierzchnia działki:" />
                        <label className="contact_form_label" htmlFor="location">Lokalizacja:</label>
                        <input className="contact_form_input" type='text' name='location' id="location" placeholder="Lokalizacja:" />
                        <div className="submit_area">
                            <Button className="contact_form_submit" type="submit" endIcon={<SendIcon />} onClick={submit}>Wyślij</Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}