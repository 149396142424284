import logo from '../Images/02_Logo_300ppi.png'
import { Icon } from '@fluentui/react/lib/Icon';
import '../App.css';
interface FloveeHeaderProps {
    imageUrl: string;
    contactTitle: string;
}

export const FloveeHeader: React.FC<FloveeHeaderProps> = (props) => {
    const {
        imageUrl,
        contactTitle
    } = props;

    return (
        <div id="HeaderContainers" className="HeaderContainer">
            <div className="logo">
                <img className="logoImage" src={logo} />
            </div>
            <div className="contactUs">
                <div>
                    <Icon iconName="TransferCall" />
                </div>
                <div>
                    <div className='contactNumber'> +48 881 341 490</div>
                    <a href='mailto:zielonewizje.projekty@gmail.com'> zielonewizje.projekty@gmail.com</a>
                </div>
            </div>
        </div>
    )
}