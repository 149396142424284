
import img9 from '../Images/Banner/Image9.png'
import img4 from '../Images/Banner/Image4.png'
// import img2 from '../Images/Banner/banner2.jpg'
// import img3 from '../Images/Banner/banner3.jpg'
export const Banners = [
    // {
    //     title: "NOWOCZESNY OGRÓD Z PODPORAMI POD PNĄCZA",
    //     image: img9
    // },
    {
        title: "NOWOCZESNY OGRÓD Z PODPORAMI POD PNĄCZA",
        image: img4
    },
    // {
    //     title: "title2",
    //     image: img2
    // },
    // {
    //     title: "title3",
    //     image: img3
    // }
]