import logo from '../Images/logo.jpg'
import { Icon } from '@fluentui/react/lib/Icon';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../App.css';
import { Banners } from '../MockupData/Banners';

interface BannerProps {

}

export const Banner: React.FC<BannerProps> = (props) => {
    const {

    } = props;

    return (
        <Carousel
            autoPlay={true}
            interval={2000}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}>
            {Banners.map(banner => {
                return (<div>
                    <img src={banner.image} />
                    {/* <div className='BannerTitle'>{banner.title}</div> */}
                </div>)
            })}
        </Carousel>

    )
}