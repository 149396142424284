import logo from '../Images/logo.jpg'
import { Icon } from '@fluentui/react/lib/Icon';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../App.css';
import { Banners } from '../MockupData/Banners';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FloveeHeader } from '../Components/Header';
import { Banner } from '../Components/Banner';
import { Navi } from '../Components/Navi';

interface NotFoundProps {

}

export const NotFound: React.FC<NotFoundProps> = (props) => {
  const {

  } = props;

  return (
    <h2>404 - Not Found</h2>
  )
}