import logo from '../Images/logo_okragle.png'
import insta from '../Images/Instagram-PNGinstagram-icon-png.png'
import facebook from '../Images/facebook-logo.png'
import { Icon } from '@fluentui/react/lib/Icon';
import '../App.css';
interface FooterProps {
}

export const Footer: React.FC<FooterProps> = (props) => {
    const {
    } = props;

    return (
        <div className="Footer">
            <div className="FooterArea">
                <div className='FooterMainColl'>
                    <img className='LogoFooter' src={logo} />
                </div>
                <div className='FooterColl'>
                    <div className='FooterNode'>
                        <a className='FooterNode' href='/kontakt'>Kontakt</a>
                    </div>
                    <div className='FooterNode'>
                        <a className='FooterNode' href='/oferta'>Oferta</a>
                    </div>
                </div>
                <div className='SocialMediaNode'>
                    <div className='FooterNode'>
                        <a href="https://www.instagram.com/zielonewizje.projekty"><img className='SocialMedia' src={insta} /></a>
                    </div>
                    <div className='FooterNode'>
                        <a href="https://www.facebook.com/people/Zielone-wizje/61555962925446"><img className='SocialMedia' src={facebook} /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}