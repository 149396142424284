import React from 'react';
import { FloveeHeader } from '../Components/Header';
import { Navi } from '../Components/Navi';
import { Banner } from '../Components/Banner';
import { SelectUs } from '../Components/SelectUs';
import { Projects } from '../Components/Projects';

interface HomeProps {

}


export const Home: React.FC<HomeProps> = (props) => {
  const {

  } = props;

  return (    
    <div className="App">


    <div className='Banner'>
      <Banner />
    </div>
    <div className='SelectUs'>
      <SelectUs />
    </div>
    <div className='Projects'>
      <Projects />
    </div>
  </div>)
}