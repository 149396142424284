
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../App.css';
import profilowe from '../Images/profilowe2.jpg'
interface SelectUsProps {

}

export const SelectUs: React.FC<SelectUsProps> = (props) => {
    const { innerWidth: width } = window;
    const phoneOption = width < 1001;
    return (
        <div>
            <div className='SelectUsHeader'>
                <span className='CompanyName'>Zielone wizje</span> na piękny i funkcjonalny ogród
            </div>
            <div className='SelectUsRow'>
                <div className='ProfileImageArea'>
                    <img className="profileImage" alt="demoImage" src={profilowe}></img>
                </div>
                <div className='SelectUsText'>
                    <div className='SelectUsDetailsText PCArea'>
                        <div className='SelectUsCellHeader'>Czym się zajmuję?</div>
                        <div className='SelectUsCellContext'>
                            Projektuję niewielkie ogrody naturalistyczne i nowoczesne, których powierzchnia nie przekracza 500 m2. Najczęściej znajdują się one przy domach szeregowych i tzn. bliźniakach.
                        </div>
                    </div>
                    {!phoneOption &&
                        <div className='SelectUsDetailsText PCArea'>
                            <div className='SelectUsCellHeader'>Co mnie wyróżnia?</div>
                            <div className='SelectUsCellContext'>
                                Tworzę rozwiązania w których istotną rolę odgrywa funkcjonalność i estetyka. Zwracam szczególną uwagę, aby ogród nawiązywał stylem do budynku i stał się jego integralną częścią. Przykładam szczególną wagę, aby każdy jego metr był dobrze wykorzystany.
                            </div>
                            <div className='SelectUsCellContextLastAcapit'>
                                Spotkajmy się w Twoim przyszłym ogrodzie, aby wkrótce stał się on wspaniałym miejscem do obcowania z przyrodą i wypoczynku dla całej rodziny.
                            </div>
                        </div>
                    }
                </div>

            </div>
            {phoneOption &&
                <div className='SelectUsRow PhoneArea'>
                    <div className='SelectUsDetailsText'>
                        <div className='SelectUsCellHeader'>Co mnie wyróżnia?</div>
                        <div className='SelectUsCellContext'>
                            Tworzę rozwiązania w których istotną rolę odgrywa funkcjonalność i estetyka. Zwracam szczególną uwagę, aby ogród nawiązywał stylem do budynku i stał się jego integralną częścią. Przykładam szczególną wagę, aby każdy jego metr był dobrze wykorzystany.
                        </div>
                        <div className='SelectUsCellContextLastAcapit'>
                            Spotkajmy się w Twoim przyszłym ogrodzie, aby wkrótce stał się on wspaniałym miejscem do obcowania z przyrodą i wypoczynku dla całej rodziny.
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}